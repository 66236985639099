<template>
  <!-- ACTION HISTORY MODAL SIDEBAR -->
  <div
    @click="$emit('overlayClicked')"
    class="modal-background-overlay2 h-16"
    :class="{ hidden: !isVisible }"
  ></div>
  <div
    class="modal-background-overlay"
    @click="$emit('overlayClicked')"
    :class="{ hidden: !isVisible }"
  ></div>
  <div
    class="modal-background-overlay3"
    @click="$emit('overlayClicked')"
    :class="{
      'modal-background-overlay--hidden': !isVisible,
      'modal-background-overlay--black': isVisible,
    }"
  ></div>
  <div
    style="z-index: 998"
    :class="{ 'modal-visible': isVisible }"
    class="
      modal
      bottom-0
      pb-12
      pt-28
      px-8
      overflow-y-auto
      bg-white
      w-1/3
      text-black
      flex flex-col
      z-50
      h-full
      fixed
    "
  >
    <div class="text-left z-50">
      <h1 class="text-xl font-bold">Add Slide</h1>

      <div class="leading-loose">
        <form @submit.prevent class="mt-4 bg-white">
          <div class="inline-block mt-2 w-full pr-1">
            <input
              class="w-full px-2 py-2 text-sm text-gray-700 bg-gray-200 rounded"
              name="actiontitle"
              type="text"
              placeholder="Name"
              v-model="newSlide.name"
            />
          </div>

          <div class="inline-block mt-2 w-full pr-1">
            <input
              class="w-full px-2 py-2 text-sm text-gray-700 bg-gray-200 rounded"
              name="actiontitle"
              type="text"
              placeholder="Button URL"
              v-model="newSlide.buttonUrl"
            />
          </div>

          <div class="font-bold mt-5">Thumbnail <span class="text-xs">( Height 430px )</span></div>
          <img
            v-if="thumbnail"
            :src="thumbnail"
            class="block mx-auto my-2"
            style="max-height: 200px; width: auto"
          />
          <label
            v-if="!files.thumbnail"
            for="thumbnailInput"
            class="text-center cursor-pointer block bg-gray-100 p-1 w-full"
            >+
            <input
              @change="onFileSelect($event, 'thumbnail')"
              type="file"
              class="hidden"
              name="thumbnailInput"
              id="thumbnailInput"
              ref="thumbnailInput"
            />
          </label>
          <button
            v-if="files.thumbnail"
            @click="files.thumbnail = null"
            for="brandLogoInput"
            class="
              text-center text-red-500
              font-bold
              cursor-pointer
              block
              bg-yellow-100
              p-1
              w-full
            "
          >
            x
          </button>

          <div class="mt-6">
            <button
              class="
                px-4
                mr-2
                py-2
                text-black
                font-light
                text-sm
                tracking-wider
                bg-gray-100
                hover:bg-gray-200
                rounded
              "
              @click.prevent="$emit('overlayClicked')"
            >
              Cancel
            </button>
            <button
              class="
                px-4
                py-2
                text-sm text-white
                font-light
                tracking-wider
                bg-gray-900
                hover:bg-gray-700
                rounded
              "
              :disabled="!canSave"
              :class="{
                'cursor-not-allowed': !canSave,
              }"
              type="submit"
              @click.prevent="saveSlide"
            >
              Add
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- INQUIRY MODAL SIDEBAR -->
</template>

<script>
import alerts from "@/utils/alert-types";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "newSlideModal",
  props: ["isVisible"],
  emits: ["overlayClicked"],
  data() {
    return {
      files: {
        thumbnail: null,
      },
      newSlide: {
        name: "",
        buttonUrl: "",
      },
    };
  },
  methods: {
    ...mapActions(["addTrendingSlide"]),
    async saveSlide() {
      try {
        const options = {
          newSlide: this.newSlide,
          files: this.files,
        };

        await this.addTrendingSlide(options);
        this.$emit("overlayClicked");
        this.files = {
          thumbnail: null,
        };
        this.newSlide = {
          name: "",
          buttonUrl: "",
        };
      } catch (e) {
        this.$errorHandler(e);
      }
    },
    onFileSelect(e, type) {
      let files = [];

      if (e.target.files.length) {
        const fileList = e.target.files;

        for (const key in fileList) {
          if (typeof fileList[key] == "object") {
            if (fileList[key].type) {
              let allowed =
                fileList[key].type === "image/jpeg" ||
                fileList[key].type === "image/jpg" ||
                fileList[key].type === "image/png" ||
                fileList[key].type === "image/gif";

              if (type === "file") {
                allowed =
                  fileList[key].type ===
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                  fileList[key].type === "application/pdf" ||
                  fileList[key].type ===
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                  fileList[key].type === "application/vnd.ms-excel";
              }

              if (allowed) {
                files.push(fileList[key]);
              } else {
                this.$store.dispatch("addAlert", {
                  ...alerts.danger,
                  alert: "one of the files you selected is not allowed!",
                });
              }
            }
          }
        }
        this.files[type] = files[0];
      }

      this.$refs[`${type}Input`].value = null;
    },
  },
  computed: {
    ...mapGetters(["documentBrands"]),
    selectedBrand() {
      if (!this.documentBrands || !this.documentBrands.length) return null;
      const selectedIndex = this.documentBrands.findIndex(
        (brand) => brand._id === this.$route.params.brandid
      );
      if (selectedIndex === -1) return null;
      return this.documentBrands[selectedIndex];
    },
    canSave() {
      return (
        this.newSlide.name &&
        this.newSlide.buttonUrl &&
        this.files &&
        this.files.thumbnail
      );
    },
    thumbnail() {
      this.files.thumbnail;

      if (!this.files || !this.files.thumbnail) return null;

      const url = URL.createObjectURL(this.files.thumbnail);

      return url;
    },
    fileName() {
      this.files.file;

      if (!this.files || !this.files.file) return null;

      return this.files.file.name;
    },
  },
  mounted() {},
};
</script>

<style scoped>
.modal-background-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 997;
}

.modal-background-overlay2 {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1001;
}

.modal-background-overlay3 {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 49;
  transition: background 0.2s 0.3s, visibility 1s;
}

.modal-background-overlay--hidden {
  visibility: hidden;
}

.modal-background-overlay--black {
  background: rgba(0, 0, 0, 0.8);
  visibility: visible;
}

.modal {
  transition: transform 0.5s;
  right: 0;
  transform: translatex(100%);
}

.modal-visible {
  transform: translatex(0%);
}
</style>