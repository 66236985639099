<template>
  <div>
    <div
      :style="{ marginLeft: topBarLeftMargin, width: contentAreaWidth }"
      class="
        content-area
        left-0
        mt-14
        w-full
        bg-gradient-to-r
        from-gray-300
        bg-gray-100
        p-1
        flex
        justify-between
        z-20
        fixed
      "
    >
      <ul class="flex items-center ml-1">
        <div>
          <svg
            @click="$router.go(-1)"
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            viewBox="0 0 24 24"
            fill="none"
            class="mr-2 hover:bg-gray-100 rounded-full px-1 cursor-pointer"
            stroke="#000000"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path d="M15 18l-6-6 6-6" />
          </svg>
        </div>
        <div class="relative text-gray-600">
          <input
            v-model="searchString"
            type="search"
            name="serch"
            placeholder="Search"
            class="
              bg-gray-100
              h-7
              w-96
              px-4
              pr-10
              rounded-lg
              text-sm
              focus:outline-none
            "
          />
          <button type="submit" class="absolute right-0 top-0 mt-1.5 mr-4">
            <svg
              class="h-4 w-4 fill-current"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              version="1.1"
              id="Capa_1"
              x="0px"
              y="0px"
              viewBox="0 0 56.966 56.966"
              style="enable-background: new 0 0 56.966 56.966; color: #cfb47e"
              xml:space="preserve"
              width="512px"
              height="512px"
            >
              <path
                d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z"
              />
            </svg>
          </button>
        </div>
      </ul>
      <ul class="flex items-center">
        <li
          style="color: #cfb47e"
          class="
            cursor-pointer
            bg-white
            border
            items-center
            text-sm
            py-1
            mr-2
            rounded-lg
            px-2
            hover:bg-gray-900
            flex
          "
          @click="NewTrendingSlideVisible = true"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-3 w-3 mr-1"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#cfb47e"
            stroke-width="2.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <line x1="12" y1="5" x2="12" y2="19"></line>
            <line x1="5" y1="12" x2="19" y2="12"></line>
          </svg>
          New Slide
        </li>
      </ul>
    </div>
  </div>

  <NewTrendingSlide
    @overlayClicked="NewTrendingSlideVisible = false"
    :isVisible="NewTrendingSlideVisible"
  />

  <div class="inq-table">
    <div>
      <div class="flex flex-col">
        <!-- There was flex grow here -->
        <div class="flex-grow relative">
          <!-- <SearchLoadingOverlay v-if="loading"></SearchLoadingOverlay> -->
          <table class="relative w-full border">
            <thead class="w-full border border-t-o border-r-0 border-l-0">
              <tr class="text-left">
                <th
                  class="
                    top-0
                    px-1
                    py-2
                    text-gray-600
                    bg-white
                    text-xs
                    hover:bg-gray-100
                    pl-2
                  "
                >
                  <input
                    type="checkbox"
                    class="form-checkbox h-4 w-4 text-gray-600"
                  />
                </th>
                <th
                  class="
                    top-0
                    px-1
                    py-2
                    text-gray-600
                    bg-white
                    text-xs
                    hover:bg-gray-100
                  "
                >
                  NAME
                </th>
                <th
                  class="
                    top-0
                    px-1
                    py-2
                    text-gray-600
                    bg-white
                    text-xs
                    hover:bg-gray-100
                  "
                >
                  ACTION
                </th>
              </tr>
            </thead>
            <tbody class="divide-y text-xs">
              <!-- Catalogue Tavle ITEM -->
              <TrendingSlideTableItem
                v-for="slide in filteredTrendingSlides"
                :key="slide._id"
                :trendingSlide="slide"
              ></TrendingSlideTableItem>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NewTrendingSlide from "@/components/modules/trendingslides/NewTrendingSlide";
import TrendingSlideTableItem from "@/components/modules/trendingslides/TrendingSlideTableItem";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "TrendingSlides",
  data() {
    return {
      searchString: "",
      NewTrendingSlideVisible: false,
    };
  },
  methods: {
    ...mapActions(["getTrendingSlides"]),
  },
  computed: {
    ...mapGetters(["trendingSlides"]),
    filteredTrendingSlides() {
      this.searchString;
      const regexp = new RegExp(this.searchString, "ig");

      return this.trendingSlides.filter((slide) => {
        return regexp.test(slide.name) || regexp.test(slide.brand);
      });
    },
    sidebarCollapsed: {
      get() {
        return this.$store.state.ui.sidebarCollapsed;
      },
      set(newVal) {
        this.$store.commit("SET_SIDEBAR_COLLAPSE_STATE", newVal);
      },
    },

    topBarLeftMargin() {
      this.sidebarCollapsed;

      if (this.sidebarCollapsed) return "4rem";
      else return "16.5%";
    },
    contentAreaWidth() {
      this.sidebarCollapsed;
      if (this.sidebarCollapsed) return "calc(100% - 4rem)";
      else return "83.5%";
    },
  },
  mounted() {
    this.getTrendingSlides();
  },
  components: {
    NewTrendingSlide,
    TrendingSlideTableItem,
  },
};
</script>

<style lang="scss" scoped>
.layout-container {
  height: 100%;
}

.sidebar {
  width: 16.5%;
}

.content-area {
  transition: margin-left 0.5s ease-in-out;
}
.inq-table {
  margin-top: 5.9rem;
  th {
    position: sticky;
    z-index: 48;
    top: 5.75rem;
  }
}
</style>